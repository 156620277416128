<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const topStripText = computed(() => {
  return route.path.includes('/roofing')
      ? 'Get approved for roofing deals in your area!'
      : 'Find top pros in your area for any project, and improve your home today';
});
</script>

<template>
  <div class="top-strip">
    <div class="container">
      <div id="top-strip-text">{{ topStripText }}</div>
    </div>
  </div>
  <div class="header">
    <div class="container">
      <a href="/">
        <img src="../../assets/images/OpenHomeSmallLogo.png" alt="Open-Home-Logo">
      </a>
      <a href="tel:+18775250006" class="button">(877) 525-0006</a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @import '../../assets/style/main';

  .top-strip {
    min-width: 100%;
    background: $dark-blue;
    #top-strip-text {
      font-family: $font-poppins;
      color: white;
      font-size: 17px;
      line-height: 19px;
      letter-spacing: .5px;
      padding: 8px 0;
      font-weight: 400;
      text-align: center;
    }
  }
  .header {
    min-width: 100%;
    background: white;
    padding: 8px 0;
    .container {
      display: flex;
      justify-content: space-between;
      align-content: center;
      img {
        max-width: 216px;
      }
      .button {
        background: -moz-linear-gradient(180deg, #ff001a, #911f2b);
        background: -webkit-linear-gradient(180deg, #ff001a, #911f2b);
        background: linear-gradient(180deg, #ff001a, #911f2b);
        border: none;
        font-size: 17px;
        font-weight: 500;
        border-radius: 5px;
        margin-top: 17px;
        font-family: $font-poppins;
        color: white;
        outline: none;
        text-decoration: none;
        padding: 10px 20px;
        float: right;
        height: auto;
        max-height: 45px;
        margin: auto 0;
        &:hover {
          background: -moz-linear-gradient(0deg, #ff001a, #911f2b);
          background: -webkit-linear-gradient(0deg, #ff001a, #911f2b);
          background: linear-gradient(0deg, #ff001a, #911f2b);
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .top-strip #top-strip-text {
      font-size: 15px;
      width: 100%;
      white-space: nowrap;
      -moz-animation: top-strip-text 15s linear 0s infinite;
      -webkit-animation: top-strip-text 15s linear 0s infinite;
    }
    .header {
      img {
        max-width: 154px;
      }
    }
  }

  @media (max-width: 470px) {
    .header .container {
      .button {
        font-size: 15px;
        padding: 5px 10px;
      }
      img {
        max-width: 154px;
      }
    }
  }

  @media (max-width: 340px) {
    .header .container {
      .button {
        font-size: 13px;
        padding: 5px 8px;
      }
      img {
        max-width: 130px;
      }
    }
  }

  @keyframes top-strip-text {
    0% {
      margin-left: 100%;
    }

    100% {
      margin-left: -600px;
    }
  }
</style>
