<script setup>
import { onMounted } from 'vue'

import HeaderWithoutButton from '@/components/shared-page-components/HeaderWithoutButton.vue'
import TheFooter from '@/components/shared-page-components/TheFooter.vue'

onMounted(() => {
  window.scrollTo({top: 0, behavior: 'smooth'})
})
</script>

<template>
  <div class="main-container">
    <HeaderWithoutButton/>
    <div class="privacy-policy">
      <div class="container">
        <div class="new-sec">
          <h6>Last Updated: 05/10/2023</h6>
          <h1>Your California Privacy Rights</h1>
          <p><i>Shine the Light</i>. If you are a resident of the State of California and would like to learn how your “personal information” (as defined in the Shine the Light Law, Cal. Civ. Code § 1798.83) is shared with third parties, what categories of personal information we have shared with third parties in the preceding year, as well as the names and addresses of those third parties, please e-mail us at: info@openhomepros.com; or send us U.S. mail to: OpenHome LLC, 12 Main St, Unit #2001 Brewster, NY 10509</p>
          <p>Further, if you are a resident of the State of California and would like to opt-out from the disclosure of your personal information to any third party for marketing purposes, please e-mail us at: info@openhomepros.com; or send us U.S. mail to: OpenHome LLC, 12 Main St, Unit #2001 Brewster, NY 10509. Please be advised that where California State residents opt-out from permitting their personal information to be shared, such individuals may still receive selected offers directly from us, in accordance with applicable law.</p>
          <p><i>California Consumer Privacy Act of 2018 (“CCPA”)</i>. In addition to the foregoing, if you are a resident of the State of California certain other privacy-related rights may apply to you in accordance with the CCPA, including the right to opt-out of our sale of your Personal Information, as well as the right to know what Personal Information about you we have collected, whether your Personal Information was shared with third-parties in the preceding year and, if so, what categories of Personal Information were shared, as well as the categories of third parties with whom we shared that Personal Information. Please see our “Privacy Provisions for California Residents” below https://www.openhomepros.com/ccpa for a more complete description of your rights under the CCPA as a California State resident.</p>
        </div>
        <div class="new-sec">
          <h2>Privacy Provisions for California Residents</h2>
          <p>These Privacy Provisions for California Residents (“<u>Provisions</u>”) supplement, and do not limit in any way, the Privacy Policy set forth above. These Provisions apply solely to residents of the State of California (“<u>CA Users</u>”). We adopt these Provisions in compliance with the California Consumer Privacy Act of 2018 (“CCPA”). Any terms defined in the CCPA have the same meaning when used in these Provisions. Users with disabilities who wish to access these Provisions in an alternative format can contact us by emailing us at: info@openhomepros.com; or sending us U.S. mail to: OpenHome LLC, 12 Main St, Unit #2001 Brewster, NY 10509</p>
          <p><u><strong>Categories of Information We Collect</strong></u></p>
          <p>We collect information that identifies, relates to, describes, references, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular CA User or device (“<u>personal information</u>”). In particular, we have collected the following categories of personal information from CA Users within the last twelve (12) months:</p>
        </div>
        <div class="new-sec">
          <table class="table table-bordered" style="margin-bottom:20px;">
            <tbody><tr>
              <th width="30%">Category</th>
              <th width="60%">Examples</th>
              <th width="10%">Collected</th>
            </tr>
            <tr>
              <td>A. Identifiers.</td>
              <td>A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, telephone number, or other similar identifiers.</td>
              <td>YES</td>
            </tr>
            <tr>
              <td>B. Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)).</td>
              <td>A name, signature, Social Security number, physical characteristics or description, postal address, telephone number, passport number, driver's license or State identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information. Some personal information included in this category may overlap with other categories.</td>
              <td>YES</td>
            </tr>
            <tr>
              <td>C. Protected classification characteristics under California or federal law.</td>
              <td>Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information).</td>
              <td>NO</td>
            </tr>
            <tr>
              <td>D. Commercial information.</td>
              <td>Records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.</td>
              <td>YES</td>
            </tr>
            <tr>
              <td>E. Biometric information.</td>
              <td>Genetic, physiological, behavioral, and biological characteristics, or activity patterns used to extract a template or other identifier or identifying information, such as, fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or exercise data.</td>
              <td>NO</td>
            </tr>
            <tr>
              <td>F. Internet or other similar network activity.</td>
              <td>Browsing history, search history, information on a CA User's interaction with a website, application or advertisement.</td>
              <td>YES</td>
            </tr>
            <tr>
              <td>G. Geolocation data.</td>
              <td>Physical location or movements.</td>
              <td>NO</td>
            </tr>
            <tr>
              <td>H. Sensory data.</td>
              <td>Audio, electronic, visual, thermal, olfactory, or similar information.</td>
              <td>NO</td>
            </tr>
            <tr>
              <td>I. Professional or employment-related information.</td>
              <td>Current or past job history or performance evaluations.</td>
              <td>NO</td>
            </tr>
            <tr>
              <td>J. Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).</td>
              <td>Education records directly related to a student maintained by an educational institution or party acting on its behalf, such as grades, transcripts, class lists, student schedules, student identification codes, student financial information, or student disciplinary records.</td>
              <td>NO</td>
            </tr>
            <tr>
              <td>K. Inferences drawn from other personal information.</td>
              <td>Profile reflecting a person's preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.</td>
              <td>NO</td>
            </tr>
            </tbody></table>
          <p>Personal information does not include:</p>
          <ul>
            <li>Publicly available information from government records.</li>
            <li>De-identified or aggregated CA User information.</li>
            <li>Information excluded from the CCPA's scope, such as:
              <ul>
                <li>health or medical information covered by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial data; and</li>
                <li>personal information covered by certain sector-specific privacy laws, including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA), and the Driver's Privacy Protection Act of 1994.</li>
              </ul>
            </li>
          </ul>
          <p>We obtain the categories of personal information listed above from the following categories of sources (with the specific categories of personal information indicated in parenthesis</p>
          <ul>
            <li>Directly from our CA Users. For example, from online registration forms that our CA Users submit to us in connection with the products and/or services that we offer by and through the Site. (Category A, B and D)</li>
            <li>Indirectly from our CA Users. For example, through information we collect from our CA Users in the course of providing our products and/or services to them. (Category A, B, D and F)</li>
            <li>Directly and indirectly from activity on the Site. This includes the type of browser that you use (e.g., Safari, Chrome, Internet Explorer), your IP address, the type of operating system that you use (e.g., Windows or iOS) and the domain name of your Internet Service Provider. In addition, we obtain certain Site usage details and analytics as same are collected automatically by us and our third party partners. (Category F)</li>
            <li>From third-parties that interact with us in connection with the products and/or services that we offer to our CA Users. For example, third party entities that assist us in sending direct and electronic mail, removing duplicate information from CA User lists, analyzing data and providing marketing analysis. (Category A, B, D and F)</li>
          </ul>
        </div>
        <div class="new-sec">
          <p><u><strong>Use of Personal Information</strong></u></p>
          <p>We may use or disclose the personal information that we collect (including (a) name; (b) mailing address; (c) e-mail address; (d) telephone number; and (e) current utility/provider of electricity) for one or more of the following business purposes (with the specific categories of personal information indicated in parenthesis)</p>
          <ul>
            <li>To fulfill or meet the reason for which the information is provided. For example, if you provide us with personal information in connection with your purchase of products and/or services, we will use that information to process your order. (Category A, B and D)</li>
            <li>To provide you with information, products or services that you request from us. (Category A, B, D and F)</li>
            <li>To create, maintain, customize and secure your account with us. (Category A, B, D and F)</li>
            <li>To provide you with e-mail, direct mail and telemarketing messages concerning certain Company products and/or services, as well as third-party products and/or services, that we believe may be of interest to you. (Category A, B, D and F)</li>
            <li>To deliver relevant Site content and advertisements to you and measure or understand the effectiveness of the advertising we serve to you. (Category A, B, D and F)</li>
            <li>To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including the Site’s Terms and Conditions. (Category A, B, D and F)</li>
            <li>To improve the Site and better present its contents to you. (Category A, B, D and F)</li>
            <li>For customer service purposes and to respond to inquiries from you. (Category A, B and D)</li>
            <li>For testing, research, analysis and product development. (Category A, B, D and F)</li>
            <li>As necessary or appropriate to protect our rights, property or safety, and that of our clients or others. (Category A, B, D and F)</li>
            <li>To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations. (Category A, B, D and F)</li>
            <li>As described to you when collecting your personal information or as otherwise set forth in the CCPA. (Category A, B, D and F)</li>
            <li>To evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation or similar proceeding, in which personal information held by us is among the assets transferred. (Category A, B, D and F)</li>
          </ul>
          <p>We will not collect additional categories of personal information or use the personal information we collected for materially different, unrelated or incompatible purposes without providing you with notice.</p>
        </div>
        <div class="new-sec">
          <p><u><strong>Sharing Personal Information</strong></u></p>
          <p>Subject to your right to opt-out of such sharing/sale, we may share, rent and/or sell your personal information from Categories A, B, D and F (including (a) name; (b) mailing address; (c) e-mail address; (d) telephone number; and (e) current utility/provider of electricity): (i) with/to third parties for marketing purposes; and (ii) for the other business purposes set forth above. The third parties that we share/sell your personal information with/to including, but not limited to marketing partners listed <a href="/marketing-partners">here</a>: who may also re-sell your personal information to additional third parties.</p>
          <p>When we disclose personal information to a third party service provider or other entity, we enter into a contractual relationship that describes the purpose for which such third party may use the personal information and requires that third party to both keep the personal information confidential and not use it for any purpose other than the performance of its services under the applicable contract. Please note, we do not collect information from CA Users that we actually know are less than eighteen (18) years of age and we do not share or sell the personal information of CA Users that we actually know are less than eighteen (18) years of age. Without limiting the foregoing, we have not shared or sold the personal information of CA Users that we actually know are less than sixteen (16) years of age in the preceding twelve (12) months.</p>
        </div>
        <div class="new-sec">
          <p>In the preceding twelve (12) months, we have disclosed the following categories of personal information for a business purpose:</p>
          <table class="table table-borderless table-sm" style="margin-bottom:15px;">
            <tbody><tr>
              <td width="10%">Category A</td>
              <td width="70%">Identifiers.</td>
            </tr>
            <tr>
              <td>Category B</td>
              <td>California Customer Records personal information categories.</td>
            </tr>
            <tr>
              <td>Category D</td>
              <td>Commercial information.</td>
            </tr>
            <tr>
              <td>Category F</td>
              <td>Internet or other similar network activity.</td>
            </tr>
            </tbody></table>
          <p>We disclose your personal information for a business purpose to the following categories of third parties(with the specific categories of personal information indicated in parenthesis:</p>
          <ul>
            <li>Our affiliates. These third parties including, but not limited to <a href="/marketing-partners">affiliates.</a> who may also re-sell your personal information to additional third parties. (Category A, B, D and F)</li>
            <li>Service providers. (Category A, B, D and F)</li>
            <li>Third parties who provide the products and/or services featured on the Site. These third parties including, but not limited to <a href="/marketing-partners">affiliates</a> who may also re-sell your personal information to additional third parties. (Category A, B, D and F)</li>
            <li>Third parties who purchase and/or license your personal information for marketing purposes, including: (a) providers of direct marketing services and applications, including lookup and reference, data enhancement, suppression and validation; (b) e-mail marketers; (c) telemarketers (where permitted by applicable law); and (d) direct marketers. These third parties including, but not limited to <a href="/marketing-partners">affiliates</a> who may also re-sell your personal information to additional third parties. (Category A, B, D and F)</li>
            <li>Third parties to whom you authorize us to disclose your personal information in connection with the products and/or services that we provide to you. These third parties including, but not limited to, <a href="/marketing-partners">affiliates</a>, who may also re-sell your personal information to additional third parties. (Category A, B, D and F)</li>
          </ul>
        </div>
        <div class="new-sec">
          <p>In the preceding twelve (12) months, we have sold the following categories of personal information to third parties:</p>
          <table class="table table-borderless table-sm" style="margin-bottom:15px;">
            <tbody><tr>
              <td width="10%">Category A</td>
              <td width="70%">Identifiers.</td>
            </tr>
            <tr>
              <td>Category B</td>
              <td>California Customer Records personal information categories.</td>
            </tr>
            <tr>
              <td>Category D</td>
              <td>Commercial information.</td>
            </tr>
            <tr>
              <td>Category F</td>
              <td>Internet or other similar network activity.</td>
            </tr>
            </tbody></table>
          <p>In the preceding twelve (12) months, we have sold personal information from Categories A, B, D and F (including (a) name; (b) mailing address; (c) e-mail address; (d) telephone number; and (e) current utility/provider of electricity) to the third parties who purchase and/or license your personal information for marketing purposes, as well as the third parties who provide the products and/or services featured on the Site. These third parties including, but no limited to, <a href="/marketing-partners">affiliates</a> who may also re-sell your personal information to additional third parties.</p>
          <p>Your information may be shared with third parties for email marketing purposes.</p>
        </div>
        <div class="new-sec">
          <p><u><strong>Your Rights and Choices</strong></u></p>
          <p>The CCPA provides CA Users (California residents) with specific rights regarding their personal information. This section describes your CCPA rights and explains how to exercise those rights.</p>
        </div>
        <div class="new-sec"> <i>Opt-Out from the Sale of Your Personal Information</i>
          <p>You have the right to opt-out of our sale of your personal information to third parties. To exercise your right to opt-out of our sale of your personal information to third parties, please submit a verifiable CA User request to us by either:</p>
          <ul>
            <li>Emailing us at: info@openhomepros.com; or</li>
            <li>Sending us U.S. mail to: OpenHome LLC, 12 Main St, Unit #2001 Brewster, NY 10509</li>
          </ul>
          <p>We endeavor to act on all opt-out requests as soon as practicable, but in all cases within fifteen (15) days of the receipt of your request.</p>
        </div>
        <div class="new-sec"> <i>Access to Specific Information and Data Portability Rights</i>
          <p>You have the right to request that we disclose certain information to you about our collection and use of your personal information over the past twelve (12) months. Once we receive and confirm your verifiable CA User request, we will disclose to you:</p>
          <ul>
            <li>The categories of personal information we collected about you.</li>
            <li>The categories of sources for the personal information we collected about you.</li>
            <li>Our business or commercial purpose for collecting or selling that personal information.</li>
            <li>The categories of third parties with whom we have shared that personal information.</li>
            <li>The specific pieces of personal information we collected about you (also called a data portability request).</li>
            <li>If we sold or disclosed your personal information for a business purpose, two separate lists disclosing:
              <ul>
                <li>sales, identifying the personal information categories that each category of recipient purchased; and</li>
                <li>disclosures for a business purpose, identifying the personal information categories that each category of recipient obtained.</li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="new-sec"> <i><strong>Deletion Request Rights</strong></i>
          <p>You have the right to request that we delete any of your personal information that we collected from you and retained, subject to certain exceptions. Once we receive and confirm your verifiable CA User request, we will delete (and direct our service providers to delete) your personal information from our (their) records, unless an exception applies; provided, however, that in some cases, strictly for regulatory compliance purposes and to better evidence/honor opt-out/unsubscribe requests (and for no other purposes), we may retain certain items of your personal information on a de-identified and aggregated basis in such a manner that the data no longer identifies you.</p>
          <p>We may deny your deletion request if retaining the information is necessary for us or our service providers to:</p>
          <ol>
            <li>Complete the transaction for which we collected the personal information, provide a good or service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform our obligations in connection with our contract with you.</li>
            <li>Detect security incidents, protect against malicious, deceptive, fraudulent or illegal activity, or prosecute those responsible for such activities.</li>
            <li>Debug products to identify and repair errors that impair existing intended functionality.</li>
            <li>Exercise free speech rights, ensure the right of another CA User to exercise her/his free speech rights or exercise another right provided for by law.</li>
            <li>Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 seq.).</li>
            <li>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information's deletion may likely render impossible or seriously impair the research's achievement, but only if you previously provided informed consent.</li>
            <li>Enable solely internal uses that are reasonably aligned with CA User expectations based on your relationship with us.</li>
            <li>Comply with a legal obligation.</li>
            <li>Make other internal and lawful uses of that information that are compatible with the context in which you provided it.</li>
          </ol>
        </div>
        <div class="new-sec"> <i>Exercising Access, Data Portability and Deletion Rights</i>
          <p>To exercise your access, data portability and/or deletion rights described above, please submit a verifiable CA User request to us by either:</p>
          <ul>
            <li>Emailing us at: info@openhomepros.com; or</li>
            <li>Sending us U.S. mail to: OpenHome LLC, 12 Main St, Unit #2001 Brewster, NY 10509</li>
          </ul>
          <p>Only you or a person registered with the California Secretary of State that you authorize to act on your behalf, may make a verifiable CA User request related to your personal information.</p>
          <p>You may only make a verifiable CA User request for access or data portability twice within a 12-month period. The verifiable CA User request must:</p>
          <ul>
            <li>Provide sufficient information that allows us to reasonably verify that you are: (1) the person about whom we collected personal information; or (2) an authorized representative.</li>
            <li>Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.</li>
          </ul>
          <p>We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm that the personal information relates to you. Making a verifiable CA User request does not require that you create an account with us. We will only use personal information provided in a verifiable CA User request to verify the requestor's identity or authority to make the request.</p>
        </div>
        <div class="new-sec">
          <p><u><strong>Response Timing and Format</strong></u></p>
          <p>We endeavor to respond to all verifiable CA User requests within forty-five (45) days of the receipt thereof. If we require more time (up to ninety (90) days), we will inform you of the reason and extension period in writing. We will deliver our written response by mail or electronically, at your option. Any disclosures that we provide will only cover the twelve (12) month period preceding the receipt of your verifiable request. The response that we provide will also explain the reasons that we cannot comply with a request, if applicable. For data portability requests, we will select a format to provide your personal information that is readily useable and should allow you to transmit the information from one entity to another entity without hindrance.</p>
          <p>We do not charge a fee to process or respond to your verifiable CA User request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.</p>
        </div>
        <div class="new-sec">
          <p><u><strong>Non-Discrimination</strong></u></p>
          <p>We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we will not:</p>
          <ul>
            <li>Deny you goods or services;</li>
            <li>Charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties;</li>
            <li>Provide you a different level or quality of goods or services; and/or</li>
            <li>Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.</li>
          </ul>
        </div>
        <div class="new-sec">
          <p><u><strong>Changes to these Privacy Provisions</strong></u></p>
          <p>We reserve the right to amend these Privacy Provisions in our discretion and at any time. When we make changes to these Privacy Provisions, we will notify you by email or through a notice on the Site’s homepage.</p>
        </div>
        <div class="new-sec">
          <p><u><strong>Contact Information</strong></u></p>
          <p>If you have any questions or comments about these Privacy Provisions, our Privacy Policy, the ways in which we collect and use your personal information, your choices and rights regarding such use, or wish to exercise your rights under California law, please do not hesitate to contact us by either:</p>
          <ul>
            <li>Emailing us at: info@openhomepros.com; or</li>
            <li>Sending us U.S. mail to: OpenHome LLC, 12 Main St, Unit #2001 Brewster, NY 10509</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <TheFooter/>
</template>

<style lang="scss" scoped>
@import "../assets/style/main";
.main-container {
  display:flex;
  flex-direction: column;
  .privacy-policy {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding: 0 10px;
    background: $dark-blue;
    .container {
      padding: 25px;
      margin: 35px auto 60px;
      border-radius: 8px;
      background: white;
      box-shadow: 0 9px 6px -7px black;
      font-family: $font-poppins;
      text-align: left;
      h1 {
        font-size: 40px;
        margin-bottom: 55px;
        color: $dark-blue;
        text-align: center;
      }
      h3 {
        font-size: 25px;
        margin: 55px 0 10px;
      }
      p {
        font-size: 16px;
        margin-bottom: 25px;
        a {
          color: $dark-blue;
        }
      }
    }
  }
}
</style>