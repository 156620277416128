<script setup>
import { onMounted } from 'vue'
import HeaderWithoutButton from '../components/shared-page-components/HeaderWithoutButton.vue'
import TheFooter from '../components/shared-page-components/TheFooter'

onMounted(() => {
  if (window.location.hash === '#california-resident') {
    const element = document.getElementById('california-resident');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  } else {
    window.scrollTo(0, 0)
  }
})
</script>

<template>
  <div class="main-container">
    <HeaderWithoutButton />
    <div class="privacy-policy">
      <div class="container">
        <h1>
          PRIVACY NOTICE
        </h1>
        <p>
          Revised Date: August 1st, 2023
        </p>
        <p>
          Open Home Pros and its affiliated companies (“we” or “us”) respect the privacy of all individuals.
          This Privacy Policy (the “Privacy Policy”) governs our collection, use, and disclosure of your Personal
          Information (as defined below) in connection with your use of our website(s) on which this Privacy Policy
          is linked (collectively, “Sites”) and any transactions, interactions, or other engagements you might have
          with us (collectively, “Services”). For clarity, the term “Sites” does not include any sites owned or
          operated by third parties.
        </p>
        <p>
          Please read this Privacy Policy carefully. By using the Sites or Services, you consent to the collection
          and use of your information by us as set out in this Privacy Policy. This Privacy Policy forms a part of
          and is incorporated into our <a href="/terms-of-use">Terms of Service</a>, and any capitalized terms used
          but not otherwise defined in this Privacy Policy will have the meanings given to them in the Terms of
          Service. IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, YOU ARE NOT PERMITTED TO USE OR
          ACCESS THE SITE.
        </p>
        <h3>
          ABOUT THIS PRIVACY POLICY
        </h3>
        <p>
          <strong>
            Privacy Policy Changes
          </strong>
        </p>
        <p>
          This Privacy Policy is subject to change. We encourage you to review this Privacy Policy frequently for
          any revisions or amendments. If we make any material changes to this Privacy Policy, the revised policy
          will be posted on the Sites with the date of revision. Changes will be effective immediately upon posting.
          You will be deemed to have been made aware of and have accepted the changes by your continued use of our
          Sites or Services. If we determine that a change to this Privacy Policy will result in using your Personal
          Information acquired before the change being used in a materially new way, we will provide notice, such as
          by a posting on the Sites or an email to your last known email address, before using your Personal
          Information in the new way.
        </p>
        <p>
          <strong>
            Conflict Between this Policy and Laws and Regulations
          </strong>
        </p>
        <p>
          When federal, state, or local laws and regulations require a higher level of protection for your Personal
          Information, they take precedence over this Privacy Policy. The specific requirements of this Privacy Policy
          apply unless disallowed by applicable laws or regulations. Please be aware that the Sites and Services are
          subject to the laws of the United States of America and that your Personal Information will be processed in
          the United States and other jurisdictions. The Sites and Services are intended for use by individuals in the
          United States.
        </p>
        <h3>
          INFORMATION WE COLLECT
        </h3>
        <p>
          <strong>
            Personal Information
          </strong>
        </p>
        <p>
          We may collect information that personally identifies, relates to, describes, or is capable of being
          associated with you (“Personal Information”), including:
        </p>
        <ul>
          <li><i>Identifiers and Contact Information</i> such as name, address, email address, phone number;</li>
          <li><i>Personal Identifiers</i> such as education, employment status, financial information, and health insurance information;</li>
          <li><i>Protected Classification Characteristics</i> such as age, marital status, gender, and veteran or military status;</li>
          <li><i>Health Information</i> such as a medical condition or diagnosis related to your request;</li>
          <li><i>Commercial Information</i> such as the products that you have used, considered, or purchased;</li>
          <li><i>Internet and Network Information</i> such as IP address, device ID, browsing/search history, browser type, device type and settings;</li>
        </ul>
        <p>
          The specific Personal Information we collect about you may vary based on how you use the Sites and/or Services and your relationship with us.
        </p>
        <p>
          <strong>
            Usage Data and Sites Activity
          </strong>
        </p>
        <p>
          We automatically collect information in connection with the actions you take on the Sites (“Usage Data”).
          For example, each time you visit our Sites, we may automatically collect the type of web browser you use,
          the type of device you use, the type of your operating system, the name of your Internet Service Provider,
          the pages you view on our Sites, referring and exit pages, the date and time of your visit, and the number
          of clicks to, from, and within the Sites, and the duration of your visits to the Sites. If this information
          is reasonably capable of being associated with you, we treat it as Personal Information under the category
          Internet and Network Information. If this information is not reasonably capable of being individually
          associated with you, we treat it as Usage Data.
        </p>
        <p>
          <strong>
            Communication Recordings
          </strong>
        </p>
        <p>
          We may record calls and retain the content of text messages or other written/electronic communications
          between you and us. By communicating with us, you explicitly consent to our recording and retention of
          communications.
        </p>
        <h3>
          HOW WE COLLECT INFORMATION
        </h3>
        <p>
          <strong>
            From You
          </strong>
        </p>
        <p>
          We may ask you to provide us with Personal Information when you communicate with us (online or offline),
          use the Sites or Services, enter into a relationship with us, and at other times. You are not required
          to provide us your Personal Information; however, if you choose not to provide the requested information,
          you may not be able to use some or all of the features of the Sites or Services or we may not be able to
          fulfill your requested interaction.
        </p>
        <p>
          <strong>
            Cookies and Other Automated Tools
          </strong>
        </p>
        <p>
          We and third parties use cookies (small text files placed on your computer to identify your computer and
          browser) and other automated tools such as pixels to track your interaction with our Sites and to improve
          the experience of our Sites and Services, such as saving your preferences from visit to visit to present
          you with a customized version of the Website. Many web browsers are initially set up to accept cookies.
          You can reset your web browser to refuse all cookies or to indicate when a cookie is being sent. However,
          certain features of our Sites may not work if you delete or disable cookies.
        </p>
        <p>
          We use the following types of cookies:
        </p>
        <ul>
          <li><i>Strictly Necessary:</i> These cookies are essential to operate the Sites and provide you with services you have requested.</li>
          <li>
            <i>Functional:</i> These cookies enable certain features or non-essential functionality on our Sites.
            For example, we might use these cookies to recognize you and remember your preferences or settings when
            you return to our Sites so we can provide you with a more personalized experience.
          </li>
          <li>
            <i>Advertising Cookies:</i> These cookies allow us and third parties to learn more about you, including
            your use of our Sites and other websites, and to advertise products or services that might interest you
            as further indicated below.
          </li>
          <li>
            <i>Analytics Cookies:</i> These cookies help us and third parties understand how our Sites are working and
            who is visiting our Sites.
          </li>
        </ul>
        <p>
          We and third parties also use tools that enable us to track certain aspects of a user’s visit to our Sites.
          This technology helps us better manage content on our Sites by informing us what content is effective, how
          consumers engage with our Sites, and how consumers arrive at and/or depart from our Sites. The software
          typically uses two methods to track user activity: (1) “tracking pixels” and (2) “clear gifs.” Tracking
          pixels are pieces of executable code embedded in a web page that track usage activity including which
          pages are viewed, when they are viewed, and how long the pages are viewed. Clear gifs are tiny graphics
          with unique identifiers embedded in web pages and email messages that track whether a user views a web
          page or email message. User activity information may be associated with additional information about a
          user’s session and Personal Information, if provided by the user.
        </p>
        <p>
          You can opt-out of certain types of cookies and other tracking tools by clicking the cookie settings icon at
          the bottom of applicable Sites.
        </p>
        <p>
          If you arrive at our Sites by “clicking through” from another website, then certain information about you
          that you provided to that other website, such as the terms you searched that led you to our Sites, may be
          transmitted to us and we may use it. You should review the Privacy Policy of any website from which you
          reached our Sites to determine what information the operator collects and how it uses such information.
          We may retain information about you provided to us by other websites and will use it in accordance with
          this Privacy Policy. Such information may be associated with other Usage Data or Personal Information.
        </p>
        <p>
          <strong>
            Interest-Based Advertising
          </strong>
        </p>
        <p>
          We may work with third-party advertisers, search providers, and ad networks (“Ad Networks”) to learn more
          about you and show you ads or other content that we believe would be relevant to you. Advertisers may collect
          and use information about your use of our Sites or Services as well as other websites and services. These
          companies may use cookies and other online tracking technologies to collect and use your information. We
          and/or Ad Networks may also append other data to the data collected by Ad Networks to create an interest
          profile of individual users. Our Privacy Policy does not cover any use of information that an Ad Network
          may collect from you except when the Ad Network acts as our service provider. Advertisements may be shown
          via our Sites or on third-party websites. If you do not want to receive interest-based advertising, please
          visit the <a href="https://optout.aboutads.info/?c=2&lang=EN">Digital Advertising Alliance (DAA) Consumer
          Choice Page.</a> The DAA website allows you to opt-out of one or more interest-based advertising networks.
          Opt-outs are device and browser specific; therefore, you must set your opt-out preferences for each device
          and browser. Deleting browser cookies can remove your opt-out preferences; however, the DAA offers
          <a href="https://youradchoices.com/">browser extensions</a> that help preserve the opt-out preferences
          you set on the DAA's Consumer Choice Page. Please note that opt-outs only apply to interest-based advertising.
          You may still receive other types of online advertising.
        </p>
        <p>
          <strong>
            Partners and Third Parties
          </strong>
        </p>
        <p>
          We contract with publishers and other third parties that operate websites that may collect Personal
          Information about you (“Partners”). When you visit websites of our Partners, you may be redirected to
          our Sites. We may gather data directly from Partner websites, or our Partners may post data to our
          Sites or otherwise transfer data to us. This may occur via display ads, click listings, inquiry forms
          or telephonic transfers from our Partners. We also may receive data from advertisers, advertising agencies,
          and other parties that our Sites and Services may direct you to in furtherance of your inquiries and requests
          for goods and services.
        </p>
        <h3>
          HOW WE USE AND SHARE INFORMATION
        </h3>
        <p>
          We generally use Personal Information to connect you with advertisers that can provide you with information
          about the goods and services that you have expressed interest about. We may also use your Personal
          information for internal purposes, including
        </p>
        <ul>
          <li>
            <i>Providing the Sites and Services;</i>
          </li>
          <li>
            <i>Marketing and market research:</i> such as to send communications and offers and place advertisements,
            perform analytics for market research, trend analysis, and financial analysis;
          </li>
          <li>
            <i>Other company communications:</i> such as to provide you with information that may be of interest;
          </li>
          <li>
            <i>Business operations:</i> such as to evaluate, develop, and improve business operations, products and
            services offered, and perform business administration and other normal business activities;
          </li>
          <li>
            <i>Compliance:</i> such as to comply with applicable legal requirements, government requests, industry
            standards, contractual obligations, our policies, and take actions that we deem necessary to preserve and
            enforce our rights or the rights of others; and
          </li>
          <li>
            <i>Information security and fraud prevention:</i> such as to operate information security and anti-fraud
            programs.
          </li>
        </ul>
        <p>
          <strong>
            Service Providers
          </strong>
        </p>
        <p>
          We may establish a relationship with other businesses to provide services to us which may include corporate
          affiliates (“Service Providers”), including for:
        </p>
        <ul>
          <li>Provision of the Sites and Services;</li>
          <li>Facilitating marketing, non-marketing, and internal communications;</li>
          <li>Business analytics (both marketing and non-marketing related);</li>
          <li>Project management and collaboration;</li>
          <li>
            IT and network administration such as data storage and management, website hosting, data security, mobile
            application development; and
          </li>
          <li>
            Day-to-day business operations such as courier services, facilities management, electronic signatures,
            and document destruction.
          </li>
        </ul>
        <p>
          We only provide our Service Providers with the Personal Information reasonably necessary for them to perform
          these services on our behalf. You may also be asked to provide Personal Information directly to a Service
          Provider. Each Service Provider is expected to use reasonable security measures appropriate to the nature
          of the information involved to protect your Personal Information from unauthorized access, use, or disclosure.
          Service Providers are prohibited from using Personal Information other than as specified or permitted by us.
        </p>
        <p>
          We may share any of the types of Personal Information collected with one or more Service Providers.
        </p>
        <p>
          <strong>
            Third-Party Sharing/Selling
          </strong>
        </p>
        <p>
          Our providing information to our advertisers, as described above, may constitute “selling” of Personal
          Information under certain laws, including the California Consumer Protection Act. We share or sell the
          following types of Personal Information with one or more advertisers: unique identifiers, personal
          identifiers; protected classification characteristics; health information; commercial information;
          Internet and Network Information.
        </p>
        <h3>
          CHILDREN’S PRIVACY
        </h3>
        <p>
          We do not knowingly collect or solicit Personal Information from anyone under the age of 16. In the event
          that we learn that we have collected Personal Information from an individual under the age of 16, we will
          delete that information upon discovery.
        </p>
        <h3 id="california-resident">
          ADDITIONAL STATE LAW CONSUMER RIGHTS
        </h3>
        <p>
          If you are a resident of California, Colorado, Connecticut, Utah, or Virginia, you have additional rights
          to access and control your Personal Information. Exemptions may apply.
        </p>
        <p>
          <strong>
            Right to Know
          </strong>
        </p>
        <p>
          You have the right to request twice per 12-month period that we provide you (i) the categories or specific
          pieces of Personal Information we collected about you; (ii) the categories of sources from which we collected
          your Personal Information; (iii) the business or commercial purpose for which we collected your Personal
          Information; and (iv) the categories of third Parties with whom we shared or sold your Personal Information.
          We are not permitted to provide access to specific pieces of Personal Information if the Personal Information
          is sensitive or creates a high risk of potential harm from disclosure to an unauthorized person. To protect
          consumers’ Personal Information, you must provide required information and/or documentation to verify your
          identity. We will process verified requests within 45 days, subject to any applicable exceptions and
          extensions permitted by law.
        </p>
        <p>
          <strong>
            Right to Deletion
          </strong>
        </p>
        <p>
          You have the right to request that we delete any Personal Information we collected about you. Please
          understand that we are not required to honor a deletion request if a legal exemption applies such as if we
          need the information to complete a requested or reasonably anticipated transaction, prevent security
          incidents or fraud, enable internal uses that are reasonably aligned with your expectations, or comply with
          legal obligations. We will process verified requests within 45 days, subject to any applicable exceptions and
          extensions permitted by law.
        </p>
        <p>
          To prevent unauthorized individuals from making deletion requests, you must provide required information
          and/or documentation to verify your identity.
        </p>
        <p>
          <strong>
            Right to Opt-Out from the Sale of Personal Information
          </strong>
        </p>
        <p>
          You have the right to direct us not to sell your Personal Information to third parties. We will process
          requests within 15 business days, subject to any applicable exceptions and extensions permitted by law.
        </p>
        <p>
          <strong>
            Submitting a Request
          </strong>
        </p>
        <p>
          If you are a resident of a state that provides privacy rights and you would like to exercise your rights, you
          may submit a request by email to
          <a href="mailto:info@openhomepros.com">info@openhomepros.com</a> or using this
          <a href="/do-not-sell-my-information">online form</a>. After you submit your
          request, we may contact you to obtain additional information necessary to verify your identity. For example,
          we may require you to verify certain information in our files or submit a signed declaration under penalty of
          perjury verifying your identity. We will not be able to process certain requests without verifying your
          identity, so please respond promptly to our request.
        </p>
        <p>
          <strong>
            Authorized Agent
          </strong>
        </p>
        <p>
          If you are an authorized agent submitting a request on behalf of a California resident, you must provide a
          copy of a lawful power of attorney or a written signed authorization from the consumer along with proof your
          identity. You may provide this documentation via email to
          <a href="mailto:info@openhomepros.com">info@openhomepros.com</a> after submitting the
          request. We may contact you and the consumer on whose behalf you claim to act to verify your identity,
          the consumer’s identity, and your authorization to act on the consumer’s behalf.
        </p>
        <p>
          <strong>
            Non-Discrimination Notice
          </strong>
        </p>
        <p>
          We will not discriminate against any consumer for exercising their privacy rights under law or this Privacy Policy.
        </p>
        <h3>
          DO NOT TRACK SIGNALS
        </h3>
        <p>
          Because there is not yet a consensus on how companies should respond to web browser-based or other
          “Do Not Track” mechanisms, we currently do not respond to web browser Do Not Track signals that provide
          a method to opt out of the collection of information about online activities over time and across third
          party websites or online services. We may revisit our policy on responding to these signals, in which
          case we will post a revised Privacy Policy on the Sites.
        </p>
        <h3>
          DATA RETENTION
        </h3>
        <p>
          We retain your Personal Information for as long as we have a relationship with you. We also retain your
          Personal Information for a period of time after our relationship with you has ended where there is an
          ongoing business need to retain it. This includes but is not limited to retention to comply with our legal,
          regulatory, tax, and/or accounting obligations.
        </p>
        <h3>
          OTHER TRANSFERS
        </h3>
        <p>
          We may share Personal Information and Usage Data with businesses controlling, controlled by, or under
          common control with us. If we are merged, acquired, or sold, or in the event of a transfer of any of
          our assets, we may disclose or transfer Personal Information and Usage Data in connection therewith.
        </p>
        <h3>
          COMPLIANCE WITH LAWS AND LAW ENFORCEMENT
        </h3>
        <p>
          We cooperate with government and law enforcement officials and private parties to enforce and comply
          with the law. We may disclose Personal Information, Usage Data, and any other information about you to
          government or law enforcement officials or private parties if, in our sole discretion, we believe it is
          necessary or appropriate to respond to legal requests (including court orders, investigative demands,
          and subpoenas), to protect the safety, property, or rights of ourselves, consumers, or any other third
          party, to prevent or stop any illegal, unethical, or legally actionable activity, or to comply with law.
        </p>
        <h3>
          SECURITY OF YOUR INFORMATION
        </h3>
        <p>
          We use administrative, technical, and physical security measures to help protect your Personal Information.
          While we have taken reasonable steps to secure the Personal Information you provide to us, please be aware
          that despite our efforts no security measures are perfect or impenetrable and no method of data transmission
          can be guaranteed against any interception or other type of misuse. Any information disclosed online is
          vulnerable to interception and misuse by unauthorized parties. Therefore, we cannot guarantee complete
          security if you provide Personal Information to us, and we encourage you to exercise discretion regarding
          the Personal Information you choose to disclose.
        </p>
        <h3>
          HOW TO CONTACT US
        </h3>
        <p>
          If you have any questions about our Sites, Services, or this Privacy Policy, please contact us using the
          information below.
        </p>

        <p>
          Open Home Pros
          <br>
          Attn: Privacy
          <br>
          12 Main St.
          <br>
          Unit #2001
          <br>
          Brewster, NY 10509
          <br>
          <a href="mailto:info@openhomepros.com">info@openhomepros.com</a>
        </p>
      </div>
    </div>
  </div>
  <TheFooter />
</template>

<style lang="scss" scoped>
@import '../assets/style/main';
.main-container {
  display:flex;
  flex-direction: column;
  .privacy-policy {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding: 0 10px;
    background: $dark-blue;
    .container {
      padding: 25px;
      margin: 35px auto 60px;
      border-radius: 8px;
      background: white;
      box-shadow: 0 9px 6px -7px black;
      font-family: $font-poppins;
      text-align: left;
      h1 {
        font-size: 40px;
        margin-bottom: 55px;
        color: $dark-blue;
        text-align: center;
      }
      h3 {
        font-size: 25px;
        margin: 55px 0 10px;
      }
      p {
        font-size: 16px;
        margin-bottom: 25px;
        a {
          color: $dark-blue;
        }
      }
      ul {
        margin-bottom: 25px;
      }
    }
  }
}
</style>